import { AngularFireAuth } from '@angular/fire/auth';

export let type_list: string[] = ["Men", "Women", "Kids"];
export let type_source: string[] = ['MEN/', 'WOMEN/', 'KIDS/'];
export let preference_list: string[] = ["Footwear", "Legwear", "Headwear", "Bodywear"];
export let preference_source: string[] = []; //TODO: make this match preferences_list (eventually, we will need to do this dynamically based on what is in Firebase Storage)

export interface UserData { email: string; displayName: string; type: boolean[]; preferences: boolean[] }
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';

export class CommonLibrary{
    private usersCollection: AngularFirestoreCollection<UserData>;
    constructor(public auth: AngularFireAuth, private readonly afs: AngularFirestore) {
        this.usersCollection = this.afs.collection<UserData>('UserDatabase');
    }
    getAuthorizedEmail(): string {
        var email: string = "";
        this.auth.user.subscribe(user => {
            email = user.email;

        });
        return email;
    }



    getUserInformation(){
        this.usersCollection = this.afs.collection<UserData>('UserDatabase');
        return this.usersCollection;
    }

    // TODO: Need to adjust so that preference list # of trues should adjust with preference list above...
    createNewUserInDatabase(email: string, dName: string, type_list: boolean[] = [true, true, true], preference_list: boolean[] = [true, true, true, true]){
        const user: UserData = { email: email, displayName: dName, type: type_list, preferences: preference_list };
        this.usersCollection.doc(email).set(user);
    }


}