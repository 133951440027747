<ion-content class="ion-padding">
  <div class="contentHeight">
    <div class="centerContent">
      <h2>It's a match</h2>
      <p>You and Jessica have liked each other.</p>
    </div>
    <div class="centerContent1">
      <div class="ion-padding-end">
        <ion-avatar>
          <ion-img src="assets/chat/kate_1.jpg"></ion-img>
        </ion-avatar>
      </div>
      <ion-avatar>
        <ion-img src="assets/chat/chat5.jpg"></ion-img>
      </ion-avatar>
    </div>
    <div class="centerContent">
      <ion-button expand="block" color="light" (click)="closeModal()">
        <ion-icon name="chatbubbles" mode="ios" expand="icon-only" class="ion-margin-end"></ion-icon>Send a message
      </ion-button>
      <ion-button expand="block" color="light" (click)="closeModal()">
        <ion-icon name="person" mode="md" expand="icon-only" class="ion-margin-end"></ion-icon>Keep Swiping
      </ion-button>
    </div>
  </div>

</ion-content>