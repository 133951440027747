/**
* Ionic 5 Dating App https://store.enappd.com/product/ionic-tinder-dating-app-starter
 *
 * Copyright © 2018-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  // { path: 'login-phone', loadChildren: () => import('./pages/login-phone/login-phone.module').then(m => m.LoginPhonePageModule) },
  /*{
    path: 'login-phone-verification',
    loadChildren: () => import('./pages/login-phone-verification/login-phone-verification.module').then(m => m.LoginPhoneVerificationPageModule)
  },*/
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule) },
  { path: 'tinder-plus', loadChildren: () => import('./pages/tinder-plus/tinder-plus.module').then(m => m.TinderPlusPageModule) },
  { path: 'edit', loadChildren: () => import('./pages/edit/edit.module').then(m => m.EditPageModule) },
  { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule) },
  { path: 'feed', loadChildren: () => import('./pages/feed/feed.module').then(m => m.FeedPageModule) },
  { path: 'autoplay-video', loadChildren: () => import('./pages/autoplay-video/autoplay-video.module').then(m => m.AutoplayVideoPageModule) },
  { path: 'email-verification', loadChildren: () => import('./pages/email-verification/email-verification.module').then(m => m.EmailVerificationPageModule) },
  { path: 'push-notification', loadChildren: () => import('./pages/push-notification/push-notification.module').then(m => m.PushNotificationPageModule) },
  { path: 'delete-account', loadChildren: () => import('./pages/delete-account/delete-account.module').then(m => m.DeleteAccountPageModule) },
  { path: 'delete-confirm', loadChildren: () => import('./pages/delete-confirm/delete-confirm.module').then(m => m.DeleteConfirmPageModule) },
  { path: 'profile-details', loadChildren: () => import('./pages/profile-details/profile-details.module').then(m => m.ProfileDetailsPageModule) },
  //{ path: 'phone-number', loadChildren: () => import('./pages/phone-number/phone-number.module').then(m => m.PhoneNumberPageModule) },
  { path: 'show-me', loadChildren: () => import('./pages/show-me/show-me.module').then(m => m.ShowMePageModule) },
  { path: 'add-media', loadChildren: () => import('./pages/add-media/add-media.module').then(m => m.AddMediaPageModule) },
  { path: 'account-recovery', loadChildren: () => import('./pages/account-recovery/account-recovery.module').then(m => m.AccountRecoveryPageModule) },
  { path: 'login-email', loadChildren: () => import('./pages/login-email/login-email.module').then(m => m.LoginEmailPageModule) },
  { path: 'signup-email', loadChildren: () => import('./pages/signup-email/signup-email.module').then(m => m.SignupEmailPageModule) },
  { path: 'team-tinder', loadChildren: () => import('./pages/team-tinder/team-tinder.module').then(m => m.TeamTinderPageModule) },
  { path: 'change-password',loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)},
  {
    path: 'feed-settings',
    loadChildren: () => import('./pages/feed-settings/feed-settings.module').then( m => m.FeedSettingsPageModule)
  },
  {
    path: 'kloset',
    loadChildren: () => import('./pages/kloset/kloset.module').then( m => m.KlosetPageModule)
  },



];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
