/**
* Ionic 5 Dating App https://store.enappd.com/product/ionic-tinder-dating-app-starter
 *
 * Copyright © 2018-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { TinderIconsComponent } from '@app/components/tinder-icons/tinder-icons.component';
import { ShareProfileComponent } from '@app/components/share-profile/share-profile.component';
import { ChatStartComponent } from '@app/components/chat-start/chat-start.component';
import { FormsModule } from '@angular/forms';
import { ReportComponent } from '@app/components/report/report.component';
import { FeedPopoverComponent } from '@app/components/feed-popover/feed-popover.component';
import { MatchComponent } from '@app/components/match/match.component';
import { ToggleLoginComponent } from '@app/components/toggle-login/toggle-login.component';
import { RefreshComponent } from '@app/components/refresh/refresh.component';
import { LightBoxComponent } from '@app/components/lightbox/lightbox.component';
import { AccountDeleteReasonComponent } from '@app/components/account-delete-reason/account-delete-reason.component';
import { TermsComponent } from '@app/components/terms/terms.component';
import { Device } from '@ionic-native/device/ngx';

import { AmplifyAuthenticatorModule} from '@aws-amplify/ui-angular';
import { Amplify, Auth, graphqlOperation, AuthModeStrategyType } from 'aws-amplify';
import aws_exports from '../aws-exports';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import {AWSIntegration} from "@env/backend"
import { CommonLibrary } from './common.module';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { InfoPageComponent } from './components/info-page/info-page.component';

Amplify.configure({
  ...aws_exports,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
  }
});
@NgModule({
  declarations: [AppComponent,
    TinderIconsComponent,
    ShareProfileComponent,
    LightBoxComponent,
    ChatStartComponent,
    ReportComponent,
    FeedPopoverComponent,
    MatchComponent,
    ToggleLoginComponent,
    RefreshComponent,
    AccountDeleteReasonComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    ],
  entryComponents: [TinderIconsComponent,
    LightBoxComponent,
    ShareProfileComponent,
    ChatStartComponent,
    ReportComponent,
    FeedPopoverComponent,
    MatchComponent,
    ToggleLoginComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    RefreshComponent,
    AccountDeleteReasonComponent,
    InfoPageComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AWSIntegration,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AmplifyAuthenticatorModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Device,
    CommonLibrary,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

