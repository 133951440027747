<ion-content>
  <div class="flex">
    <ion-card>
      <ion-item>
        <ion-icon background="white" name="close" mode="ios" slot="end" (click)="closeModal()"></ion-icon>
      </ion-item>
      <p style="text-align: center;"><strong>Privacy Policy</strong></p>
<p>Last modified: December 7, 2022</p>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Introduction</strong></span>&nbsp;&nbsp;</p>
<p style="text-align: justify;">&nbsp;&nbsp;&nbsp;</p>
<p style="text-align: justify;">WOWDROBE LLC (the <strong>&ldquo;Company,&rdquo; &ldquo;We,&rdquo; &ldquo;Us,&rdquo; or &ldquo;Our&rdquo;</strong>) respects user&rsquo;s (&ldquo;You&rdquo; or &ldquo;Your&rdquo;) privacy and is committed to protecting it through Our compliance with this Privacy Policy (the &ldquo;<strong>Policy</strong>&rdquo;).</p>
<p style="text-align: justify;">This Policy describes the types of information We may collect from You or that You may provide when visiting the website [https://www.wowdrobe.net] (the &ldquo;<strong>Website</strong>&rdquo;) and Our practices for collecting, using, maintaining, protecting, and disclosing that information.&nbsp;</p>
<p style="text-align: justify;">This Policy applies to information We collect:</p>
<ul>
<li>On this Website;&nbsp;</li>
<li>In email, text, and other electronic messages between You and this Website; and&nbsp;</li>
<li>When You interact with Our advertising and application on third-party websites and services, if those applications or advertising include links to this Policy.&nbsp;</li>
</ul>
<p style="text-align: justify;">It does not apply to information collected by:</p>
<ul>
<li>Us offline or through any other means, including on any other website operated by the Company or any third-party (including Our affiliates and subsidiaries, as applicable); or</li>
<li>Any third-party (including Our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from (or on) the Website.</li>
</ul>
<p style="text-align: justify;">Our other websites, if applicable, and these third-parties may have their own privacy policies, which We encourage You to read before providing information on or through them.</p>
<p style="text-align: justify;">Please read this Policy carefully to understand Our policies and practices regarding Your information and how We will treat it. If You do not agree with Our policies and practices, You have the option to not utilize the Website. By accessing or using the Website, You agree to the terms of this Policy. This Policy may change from time to time (see <a href="https://docs.google.com/document/d/1wZe6GMkzyHk3dLogBh_kLx46_PNtwG_W/edit#bookmark=id.lnxbz9" target="_blank" rel="noopener">Changes to Our Privacy Policy</a>). Your continued use of the Website after We make changes is deemed to be acceptance of those changes, so please check the Policy periodically for updates.</p>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Children Under the Age of 18</strong></span>&nbsp;&nbsp;</p>
<p style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p style="text-align: justify;">Our Website is not intended for children under 18 years of age. No one under age 18 may provide any information to the Website. We do not knowingly collect Personal Information (defined below) from children under 18. If You are under 18, do not use or provide any information on the Website or on or through any of its features/register on the Website, make any purchases through the Website, use any of the interactive or public comment features of the Website, or provide any information about Yourself to Us, including Your name, address, telephone number, email address, or any screen name or user name You may use. If We learn We have collected or received Personal Information from a child under 18, We will delete that information. If You believe We might have any information from or about a child under 18, please contact Us at [help@wowdrobe.net].&nbsp;</p>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Information We Collect About You and How We Collect It</strong></span>&nbsp;&nbsp;</p>
<p style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p style="text-align: justify;">We collect several types of information from and about users of the Website, including information:</p>
<ul>
<li>By which You may be personally identified, such as name, postal address, telephone, or e-mail address (&ldquo;<strong>Personal Information</strong>&rdquo;);</li>
<li>That is about You but individually does not identify You, such as demographic information; and</li>
<li>About Your internet connection, the equipment You use to access Our Website, and usage details.</li>
</ul>
<p style="text-align: justify;">We collect this information:</p>
<ul>
<li>Directly from You when You provide it to Us;&nbsp;</li>
<li>Automatically as You navigate through the Website. Information collected automatically may include usage details;&nbsp; IP addresses, and information collected through cookies, web beacons, and other tracking technologies; and</li>
<li>When applicable, from third-parties.&nbsp;</li>
</ul>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Information You Provide to Us</strong></span>.&nbsp;</p>
<p style="text-align: justify;">The information We collect on or through the Website may include:</p>
<ul>
<li>Information that You provide by filling in forms on the Website, including information provided at the time of registering to use the Website, posting material, or requesting further services. We may also ask You for information when You report a problem with the Website;</li>
<li>Records and copies of Your correspondence (including email addresses), if You contact Us;</li>
<li>Your responses to surveys that We might ask You to complete for research purposes;&nbsp;</li>
<li>Details of transactions You carry out through the Website and fulfillment of Your orders. You may be required to provide financial information before placing an order through the Website; and</li>
<li>Your search queries on the Website.</li>
</ul>
<p style="text-align: justify;">You may also provide information to be published or displayed on public areas of the Website or transmitted to other users of the Website or third-parties (collectively, &ldquo;<strong>User Contributions</strong>&rdquo;). Your User Contributions are posted on and transmitted to others at Your own risk. Please be aware that no security measures are perfect or impenetrable. Additionally, We cannot control the actions of other users of the Website with whom You may choose to share Your User Contributions. Therefore, We cannot and do not guarantee that Your User Contributions will not be viewed by unauthorized persons.</p>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Information We Collect Through Automatic Data Collection Technologies</strong></span>.&nbsp;</p>
<p style="text-align: justify;">As You navigate through and interact with the Website, We may use automatic data collection technologies to collect certain information about Your equipment, browsing actions, and patterns, including:</p>
<ul>
<li><strong>Usage Details.</strong> Details of Your visits to the Website, including traffic data, location data, logs, and other communication data and the resources that You access and use on the Website; and</li>
<li><strong>Device Information. </strong>Information about Your computer, internet connection, and mobile device, including Your IP address, operating system, phone model, mobile network, device&rsquo;s telephone number, and browser type.</li>
</ul>
<p style="text-align: justify;">We may also use these technologies to collect information about Your online activities over time and across third-party websites or other online services (behavioral tracking). Please contact Us help@wowdrobe.net if You do not want Us to collect this information and for information on how You can opt out of behavioral tracking on or through this Website and how We respond to browser signals and other mechanisms that enable consumers to exercise choice about behavioral tracking.</p>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Information Collection and Tracking Technologies</strong></span></p>
<p style="text-align: justify;">The information We collect automatically is statistical data and does not include Personal Information, but We may maintain it or associate it with Personal Information We collect in other ways or receive from third-parties. It helps Us to improve the Website and to deliver better and more personalized services, including by enabling Us to:</p>
<ul>
<li>Estimate Our audience size and usage patterns;</li>
<li>Store information about Your preferences and/or favorite listings on the Website, allowing Us to customize the Website according to Your individual interests;&nbsp;</li>
<li>Speed up Your searches; and</li>
<li>Recognize You when You return to the Website.</li>
</ul>
<p style="text-align: justify;">The technologies We use for this automatic data collection may include:</p>
<ul>
<li><strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard drive of Your computer. You may refuse to accept browser cookies by activating the appropriate setting on Your browser. However, if You select this setting, You may be unable to access certain parts of the Website. Unless You have adjusted Your browser setting so that it will refuse cookies, Our system will issue cookies when You direct Your browser to the Website.</li>
<li><strong>Flash Cookies.</strong> Certain features of the Website may use locally stored objects (or Flash cookies) to collect and store information about Your preferences and navigation to, from, and on the Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing Your privacy and security settings for Flash cookies, contact Us at help@wowdrobe.net.&nbsp;</li>
<li><strong>Web Beacons.</strong> Pages on the Website and Our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company to count users who have visited those pages, opened an email, or for other related website statistics such as recording the popularity of certain website content and verifying system and server integrity.</li>
</ul>
<p style="text-align: justify;">We do not collect Personal Information automatically, but We may tie this information to Personal Information about You that We collect from other sources or that You provide to Us.</p>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Third-Party Information Collection</strong></span></p>
<p style="text-align: justify;">When You use the Website or its content, certain third-parties may use automatic information collection technologies to collect information about You or Your device. These third-parties may include:&nbsp;</p>
<ul>
<li>Advertisers, ad networks, and ad servers; and</li>
<li>Your service provider.</li>
</ul>
<p style="text-align: justify;">Some content or applications, including advertisements, on the Website are served by third-parties, including advertisers, ad networks, and servers, content providers, and application providers. These third-parties may use cookies (alone or in conjunction with web beacons or other tracking technologies) to collect information about You when You use this Website. The information they collect may be associated with Your Personal Information or they may collect information, including Personal Information, about Your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide You with interest-based (behavioral) advertising or other targeted content.&nbsp;</p>
<p style="text-align: justify;">We do not control these third-parties' tracking technologies or how they may be used. If You have any questions about an advertisement or other targeted content, You should contact the responsible provider directly. For information about how You can opt out of receiving targeted advertising from many providers, see &ldquo;Choices About How We Use and Disclose Your Information.&rdquo;&nbsp;</p>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>How We Use Your Information</strong></span>&nbsp;&nbsp;</p>
<p style="text-align: justify;">We use information that We collect about You or that You provide to Us, including any Personal Information, to:</p>
<ul>
<li>Present Our Website and its contents to You;</li>
<li>Provide You with information, products, or services that You request from Us;</li>
<li>Fulfill any other purpose for which You provide it;</li>
<li>Provide You with notices about Your account and subscription, respond to comments, questions, and provide customer service;</li>
<li>To carry out Our obligations and enforce Our rights arising from any contracts entered into between You and Us, including for billing and collection;&nbsp;</li>
<li>Notify You about changes to the Website or any products or services We offer or provide though it;</li>
<li>Allow You to participate in interactive features on the Website;</li>
<li>Communicate about promotions, upcoming events, and other news about products and services offered by Us and Our selected partners;&nbsp;</li>
<li>In any other way We may describe when You provide the information; and</li>
<li>For any other purpose with Your consent.</li>
</ul>
<p style="text-align: justify;">The usage information We collect helps Us to improve Our Website and to deliver a better and more personalized experience by enabling Us to:</p>
<ul>
<li>Estimate Our audience size and usage patterns;</li>
<li>Store information about Your preferences, allowing Us to customize Our Website according to Your individual interests;</li>
<li>Speed up Your searches; and</li>
<li>Recognize when You use the Website.</li>
</ul>
<p style="text-align: justify;">We may also use Your information to contact You about Our own and third-parties' goods and services that may be of interest to You.&nbsp;</p>
<p style="text-align: justify;">We may use the information We collect to display advertisements to Our advertisers' target audiences. Even though We do not disclose Your Personal Information for these purposes without Your consent, if You click on or otherwise interact with an advertisement, the advertiser may assume that You meet its target criteria.</p>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Disclosure of Your Information</strong></span>&nbsp;&nbsp;</p>
<p style="text-align: justify;">We may disclose aggregated information about Our users, and information that does not identify any individual, without restriction.</p>
<p style="text-align: justify;">We may disclose Personal Information that We collect or You provide as described in this Policy:</p>
<ul>
<li>To Our subsidiaries and affiliates;</li>
<li>To contractors, service providers, and other third-parties We use to support Our business and who are bound by contractual obligations to keep Personal Information confidential and use it only for the purposes for which We disclose it to them;</li>
<li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of the Company&rsquo;s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Information held by the Company about the Website&rsquo;s users is among the assets transferred;</li>
<li>To third-parties to market their products or services to You. We contractually require these third parties to keep Personal Information confidential and use it only for the purposes for which We disclose it to them;</li>
<li>To fulfill the purpose for which You provide the Personal Information;</li>
<li>For any other purpose disclosed by Us when You provide the Personal Information; and</li>
<li>With Your consent.</li>
</ul>
<p style="text-align: justify;">We may also disclose Your Personal Information:</p>
<ul>
<li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request;</li>
<li>To enforce or apply Our Terms of Use and other agreements, including for billing and collection purposes; and</li>
<li>If We believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, Our customers, or others, including exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
</ul>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Choices About How We Use and Disclose Your Information</strong></span>&nbsp;&nbsp;</p>
<p style="text-align: justify;">We strive to provide You with choices regarding the Personal Information You provide to Us. We have created mechanisms to provide You with the following control over Your information:</p>
<ul>
<li><strong>Tracking Technologies and Advertising.</strong> You can set Your browser to refuse all or some browser cookies, or to alert You when cookies are being sent. To learn how You can manage Your Flash cookie settings, visit the Flash player settings page on Adobe&rsquo;s <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html" target="_blank" rel="noopener">website</a>. If You disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</li>
</ul>
<ul>
<li><strong>Promotional Offers from the Company.</strong> If You do not wish to have Your email address/contact information used by the Company to promote Our own or third-parties&rsquo; products or service may send Us an email asking to be omitted from disclosures of this nature. If We have sent You a promotional email, You may send Us a return email asking to be omitted from future email distributions.</li>
<li><strong>Targeted Advertising by the Company.</strong> If You do not want Us to use information that We collect or that You provide to Us to deliver advertisements according to Our advertisers' target-audience preferences, You can opt-out by emailing help@wowdrobe.net.&nbsp;</li>
<li><strong>Disclosure of Your Information for Third-Party Advertising and Marketing.</strong> If You do not want Us to share Your Personal Information with unaffiliated or non-agent third-parties for advertising and marketing purposes, You can opt-out by emailing help@wowdrobe.net.&nbsp;</li>
</ul>
<p style="text-align: justify;">We do not control third-parties&rsquo; collection or use of Your information to serve interest-based advertising. However, these third-parties may provide You with ways to choose not to have Your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (&ldquo;<strong>NAI</strong>&rdquo;) on the NAI&rsquo;s website.</p>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Accessing and Correcting Your Information</strong></span>&nbsp;&nbsp;</p>
<p style="text-align: justify;">You can review and change Your Personal Information by sending Us an email at help@wowdrobe.net to request access to, correct, or delete any Personal Information that You have provided to Us. We may not accommodate a request to change information if We believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
<p style="text-align: justify;">If You delete Your User Contributions from the Website, copies of Your User Contributions may remain viewable in cached and archived pages or might have been copied or stored by other Website users. Proper access and use of information provided on the Website, including User Contributions, is governed by Our Terms of Use.</p>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Data Security</strong></span>&nbsp;&nbsp;</p>
<p style="text-align: justify;">We have implemented measures designed to secure Your Personal Information from accidental loss and from unauthorized access, use, alteration, and disclosure. The safety and security of Your Personal Information also depends on You. Where We have given You (or where You have chosen) a password for access to certain parts of Our Website, You are responsible for keeping this password confidential. We ask You not to share Your password with anyone.&nbsp;</p>
<p style="text-align: justify;">Unfortunately, the transmission of information via the internet is not completely secure. Although We take reasonable actions to protect Your Personal Information, We cannot guarantee the security of Your Personal Information transmitted to the Website. Any transmission of Personal Information is at Your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.</p>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Changes to Our Privacy Policy</strong></span>&nbsp;&nbsp;</p>
<p style="text-align: justify;">It is Our policy to post any changes We make to Our Privacy Policy on this page with a notice on the Website&rsquo;s home page that the Privacy Policy has been updated. If We make material changes to how We treat Our users&rsquo; Personal Information, We will notify You by email to the email address provided or through a notice on the home page for the Website. The date the Privacy Policy was last revised is identified at the top of the page. You are responsible for ensuring We have an up-to-date active and deliverable email address for You, and for periodically visiting Our Website and this Privacy Policy to check for any changes.</p>
<p style="text-align: justify;"><span style="text-decoration: underline;"><strong>Contact Information</strong></span>&nbsp;&nbsp;</p>
<p style="text-align: justify;">To ask questions or comment about this Privacy Policy and Our privacy practices, contact Us at help@wowdrobe.net.</p>
    </ion-card>
  </div>
</ion-content>