/**
* Ionic 5 Dating App https://store.enappd.com/product/ionic-tinder-dating-app-starter
 *
 * Copyright © 2018-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataService } from '@app/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toggle-login',
  templateUrl: './toggle-login.component.html',
  styleUrls: ['./toggle-login.component.scss']
})
export class ToggleLoginComponent implements OnInit {

  constructor(public modalCtrl: ModalController, public service: DataService, public route: Router) { }

  ngOnInit() {
    console.log('ngOnInit');
  }
  toggleContentClose() {
    this.modalCtrl.dismiss();
  }
  gotAccountRecovery() {
    this.modalCtrl.dismiss();
    this.route.navigate(['account-recovery']);
  }
  loginWithPhone() {
    this.modalCtrl.dismiss();
    this.route.navigate(['login-phone']);

  }
}
