<ion-content [fullscreen]="true">

  <ion-slides [options]="slideOpts" scrollbar="true" (ionSlideDidChange)="change($event)">
    <ion-slide *ngFor="let slide of slidesData">
      <ion-card [ngStyle]="{'background':'url('+ slide +')','background-size':'contain','background-repeat': 'no-repeat'}"
        (click)="openInLightBox(slide)">
        <ion-fab *ngIf="!isIos" vertical="top" horizontal="end" slot="fixed" class="topIcon">
          <ion-fab-button>
            <ion-icon name="more" (click)="openPopoverOptions($event)" color="light" mode="ios"></ion-icon>
          </ion-fab-button>
        </ion-fab>
      </ion-card>
    </ion-slide>
  </ion-slides>

  <div class="position-relative">
    <ion-fab vertical="top" horizontal="end" slot="fixed" (click)="goBack()">
      <ion-fab-button color="blumine">
        <ion-icon color='creamlight' name="arrow-down"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-row>
      <ion-col size="12">
        <ion-item>
          <h3>{{name}}</h3>
        </ion-item>
      </ion-col>
    </ion-row>
    
    <ion-item class="sameLineContent">
      <p>{{quote}}</p>
    </ion-item>
    <!-- <ion-list class="nopaddingBottom ion-padding-horizontal">
      <ion-slides pager="false" (ionSlideDidChange)="change($event)">
        <ion-slide class="slideHeight">
          <ion-row>
            <ion-col size="4" *ngFor="let slide of slidesData">
              <div [ngStyle]="{'background':'url('+ slide.slideImage +')','background-size':'cover'}"
                class="profilePics">
              </div>
            </ion-col>
          </ion-row>
        </ion-slide>
      </ion-slides>

    </ion-list>
    <ion-list class="extraPadding">
      <ion-item (click)="goForReport()">
      </ion-item>
    </ion-list> -->
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <div class="footerContent">
      <div class="icon_background" *ngFor="let icon of icons;let i = index"
        [ngClass]="{'hide-icon': i === 0 || i === 4, 'transform-icon': i === 1 || i === 3}">
        <ion-icon name={{icon.title}} expand="icon-only" color={{icon.color}} (click)="clickedIconIs(icon.title)"
          class="icon_size" mode={{icon.mode}}></ion-icon>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>