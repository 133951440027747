import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { Auth} from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import {AmazonLinks, UserKloset} from '../models';

@NgModule({})
export class AWSIntegration{
  pathToClothesDatabase = 'deep-fasion-data/';
  pathToClothesDatabaseType = ['MEN/', 'WOMEN/'];

  constructor(
    private router: Router

  ){

  }
  

  // Sends verification email with a one time verification code to the user's email (can be configured for phone number)
  async resendEmail(email) {
    try {
        await Auth.resendSignUp(email);
        console.log('code resent successfully');
    } catch (err) {
        console.log('error resending code: ', err);
    }
  }

  // Confirms the email using the code
  async confirmCode(email, confirmationCode) {
    await Auth.confirmSignUp(email, confirmationCode);
    this.router.navigate(['/home']);
  }



  async getAmazonHardcodedLinks(){
    let filelist = [];
    // let currentlyAuthenticated = await Auth.currentAuthenticatedUser();
    // console.log("The current authenticated user is: "+currentlyAuthenticated);

    let AmazonHardcoreLink = DataStore.observeQuery(AmazonLinks)

    return AmazonHardcoreLink;
  }

  async getAmazonHardcodedLink(id){
    let file: any;
    let currentlyAuthenticated = await Auth.currentAuthenticatedUser();
    console.log("The current authenticated user is: "+currentlyAuthenticated);

    await DataStore.query(AmazonLinks, id).then( link =>{
      console.log("Got links");
      // console.log(link);
      file = {
        id: link.id,
        name: link.name,
        link: link.link,
        type: link.type,
        pref: link.pref,
        images: link.image,
      };
    });
    console.log(file)
    return file;
  }

  async removeItemFromKloset(id){
    DataStore.query(UserKloset).then( async original =>{
      console.log(original[0]);
      
      await DataStore.save(
        UserKloset.copyOf(original[0], updated => {
          let newKloset = original[0].kloset.filter((item) => {return item != id});
          updated.kloset = Object.assign([], newKloset);
        })
      );
      });
  }

  async addItemToKloset(id){
    DataStore.query(UserKloset).then( async original =>{
      console.log(original[0]);
      await DataStore.save(
        UserKloset.copyOf(original[0], updated => {
          updated.kloset.push(id)
        })
      );
      });
  }
}