// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { UserSettings, UserKloset, AmazonLinks, KlosetGroups } = initSchema(schema);

export {
  UserSettings,
  UserKloset,
  AmazonLinks,
  KlosetGroups
};