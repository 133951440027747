<ion-content>
  <ion-list>
    <ion-item (click)="closePopOver()">
      <p class="ion-text-capitalize">Unmatch</p>
    </ion-item>
    <ion-item (click)="reportPopOver()">
      <p class="ion-text-capitalize">report</p>
    </ion-item>
    <ion-item (click)="closePopOver()">
      <p class="ion-text-capitalize">share profile</p>
    </ion-item>
    <ion-item (click)="openChatPopOver()">
      <p class="ion-text-capitalize">open chat</p>
    </ion-item>
    <ion-item (click)="openProfileOver()">
      <p class="ion-text-capitalize">open profile</p>
    </ion-item>
  </ion-list>
</ion-content>