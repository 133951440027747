/**
* Ionic 5 Dating App https://store.enappd.com/product/ionic-tinder-dating-app-starter
 *
 * Copyright © 2018-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { DataService } from '@app/services/data.service';
import { ChatStartComponent } from '../chat-start/chat-start.component';
import { ReportComponent } from '../report/report.component';

@Component({
  selector: 'app-feed-popover',
  templateUrl: './feed-popover.component.html',
  styleUrls: ['./feed-popover.component.scss']
})
export class FeedPopoverComponent implements OnInit {

  constructor(public popCtrl: PopoverController, public route: Router, public service: DataService) { }
  closePopOver() {
    this.popCtrl.dismiss();
  }
  openChatPopOver() {
    this.popCtrl.dismiss();
    this.service.openModal(ChatStartComponent, '');
  }
  reportPopOver() {
    this.popCtrl.dismiss();
    this.service.openModal(ReportComponent, '');
  }
  openProfileOver() {
    this.popCtrl.dismiss();
    this.route.navigate(['edit']);
  }
  ngOnInit() {
    console.log('ngOnInit');
  }

}
