/**
* Ionic 5 Dating App https://store.enappd.com/product/ionic-tinder-dating-app-starter
 *
 * Copyright © 2018-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */


import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})

export class DataService {

  constructor(public modalCtrl: ModalController) { }

  async openModal(comp, props, cssClass?) {
    const modal = await this.modalCtrl.create({
      component: comp,
      componentProps: { value: props },
      cssClass: cssClass
    });
    return modal.present();
  }
}
