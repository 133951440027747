/**
* Ionic 5 Dating App https://store.enappd.com/product/ionic-tinder-dating-app-starter
 *
 * Copyright © 2018-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss']
})
export class RefreshComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('ngOnInit');
  }

}
